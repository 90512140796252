// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

window.Rails = Rails

import 'bootstrap'

// Incompatible with Bootstrap v .5
//import 'data-confirm-modal'

require("chosen-js")

global.Swal = require("sweetalert2")

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

// Initialize Chosen
document.addEventListener("turbolinks:load", () => {
	$(".chosen-select").chosen()
});

// 6.0
// // Initialize FontAwesome
import { config, library, dom } from '@fortawesome/fontawesome-svg-core'	// import the basics library

import { fas } from '@fortawesome/pro-solid-svg-icons'                 // import the solids library
library.add(fas)

import { far } from '@fortawesome/pro-regular-svg-icons'               // import the regular library
library.add(far)

import { fab } from '@fortawesome/free-brands-svg-icons'               // import the brands library
library.add(fab)

import { fal } from '@fortawesome/pro-light-svg-icons'               // import the ligths library
library.add(fal)

import { fat } from '@fortawesome/pro-thin-svg-icons'               // import the thin library
library.add(fat)

import { fad } from '@fortawesome/pro-duotone-svg-icons'               // import the duotone library
library.add(fad)

// Change the config to fix the flicker
config.mutateApproach = 'sync'

// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()


global.intlTelInput = require("intl-tel-input")

// Include the assets

import "../stylesheets/application.scss"


// Include Images
require.context('../images', true)

global.$ = $;
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
